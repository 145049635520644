import React from "react";

function Construction() {
  return (
    <>
      <div className="chambres-banner">
        <h2>Page en Maintenance</h2>
      </div>
    </>
  );
}

export default Construction;
